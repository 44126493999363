import { combineReducers } from 'redux';
import language from './language';
import snackbar from './snackbar';
import home from './home';
import login from './login';
import accessToken from './accessToken';
import event from './event';
import gatedAccess from './gatedAccess';
import entries from './entries';

export default combineReducers({
    language,
    snackbar,
    home,
    login,
    accessToken,
    event,
    gatedAccess,
    entries,
});
