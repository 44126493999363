import { combineReducers } from 'redux';
import {
    DELETE_FORM_DIALOG_HIDE,
    DELETE_FORM_DIALOG_SHOW,
    ORDERS_FETCH_ERROR,
    ORDERS_FETCH_IN_PROGRESS,
    ORDERS_FETCH_SUCCESS,
    STATS_FETCH_ERROR,
    STATS_FETCH_IN_PROGRESS,
    STATS_FETCH_SUCCESS, SUPPORT_FORM_DELETE_ERROR, SUPPORT_FORM_DELETE_IN_PROGRESS, SUPPORT_FORM_DELETE_SUCCESS,
    SUPPORT_FORMS_FETCH_ERROR,
    SUPPORT_FORMS_FETCH_IN_PROGRESS,
    SUPPORT_FORMS_FETCH_SUCCESS, SUPPORT_FORMS_UPDATE_ERROR,
    SUPPORT_FORMS_UPDATE_IN_PROGRESS, SUPPORT_FORMS_UPDATE_SUCCESS,
    TICKETS_INFO_FETCH_ERROR,
    TICKETS_INFO_FETCH_IN_PROGRESS,
    TICKETS_INFO_FETCH_SUCCESS,
} from '../constants/home';
import { DEFAULT_COUNT, DEFAULT_ORDER, DEFAULT_SORT_BY } from '../config';

const tickets = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case TICKETS_INFO_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case TICKETS_INFO_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case TICKETS_INFO_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const orders = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case ORDERS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case ORDERS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case ORDERS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const stats = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case STATS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case STATS_FETCH_SUCCESS:
        return {
            inProgress: false,
            value: action.value,
        };
    case STATS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const supportForms = (state = {
    list: [],
    inProgress: false,
    skip: 0,
    limit: 10,
    count: DEFAULT_COUNT,
    sortBy: DEFAULT_SORT_BY,
    order: DEFAULT_ORDER,
}, action) => {
    switch (action.type) {
    case SUPPORT_FORMS_FETCH_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SUPPORT_FORMS_FETCH_SUCCESS:
        return {
            ...state,
            list: action.items,
            inProgress: false,
            count: action.count,
            skip: action.skip,
            limit: action.limit,
            sortBy: action.sortBy,
            order: action.order,
        };
    case SUPPORT_FORMS_FETCH_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const updateSupportForms = (state = {
    inProgress: false,
    value: {},
}, action) => {
    switch (action.type) {
    case SUPPORT_FORMS_UPDATE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SUPPORT_FORMS_UPDATE_SUCCESS:
        return {
            ...state,
            value: action.value,
            inProgress: false,
        };
    case SUPPORT_FORMS_UPDATE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deleteSupportForm = (state = {
    inProgress: false,
    value: '',
}, action) => {
    switch (action.type) {
    case SUPPORT_FORM_DELETE_IN_PROGRESS:
        return {
            ...state,
            inProgress: true,
        };
    case SUPPORT_FORM_DELETE_SUCCESS:
        return {
            ...state,
            value: action.value,
            inProgress: false,
        };
    case SUPPORT_FORM_DELETE_ERROR:
        return {
            ...state,
            inProgress: false,
        };
    default:
        return state;
    }
};

const deleteFormDialog = (state = {
    open: false,
    value: {},
}, action) => {
    switch (action.type) {
    case DELETE_FORM_DIALOG_SHOW:
        return {
            open: true,
            value: action.value,
        };
    case DELETE_FORM_DIALOG_HIDE:
        return {
            open: false,
            value: {},
        };

    default:
        return state;
    }
};

export default combineReducers({
    tickets,
    orders,
    stats,
    supportForms,
    updateSupportForms,
    deleteSupportForm,
    deleteFormDialog,
});
