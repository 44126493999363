import React from 'react';
import { withRouter } from 'react-router-dom';
import DataTable from '../../components/DataTable';
import { connect } from 'react-redux';
import * as PropTypes from 'prop-types';
import CircularProgress from '../../components/CircularProgress';
import { fetchTicketsInfo } from '../../actions/home';

const TicketsInfoTable = (props) => {
    const options = {
        textLabels: {
            body: {
                noMatch: props.inProgress
                    ? <CircularProgress/>
                    : <div className="no_data_table"> No data found </div>,
                toolTip: 'Sort',
            },
            viewColumns: {
                title: 'Show Columns',
                titleAria: 'Show/Hide Table Columns',
            },
        },
        onSearchChange: (searchText) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketsInfo(props.skip, props.limit, props.sortBy, props.order, searchText);
        },
        onChangePage: (currentPage) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketsInfo(props.limit * currentPage, props.limit, props.sortBy, props.order, props.searchKey);
        },
        onChangeRowsPerPage: (numberOfRows) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketsInfo(props.skip, numberOfRows, props.sortBy, props.order, props.searchKey);
        },
        onColumnSortChange: (changedColumn, direction) => {
            if (props.list && props.list.length === 0) {
                return;
            }

            props.fetchTicketsInfo(props.skip, props.limit, changedColumn, direction, props.searchKey);
        },
        responsive: 'standard',
        serverSide: true,
        pagination: true,
        count: props.total,
        selectableRows: 'none',
        download: false,
        filter: false,
        print: false,
        search: false,
        viewColumns: true,
        title: true,
    };

    const columns = [{
        name: 'id',
        label: 'Ticket ID',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="user_address">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'status',
        label: 'Ticket Status',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="user_address">{value}
                    </div>
                );
            },
        },
    }, {
        name: 'owner_name',
        label: 'Name',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'email_address',
        label: 'Email',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value}
                    </div>
                );
            },
        },
    }, {
        name: 'claim.status',
        label: 'status',
        options: {
            customBodyRender: function (value) {
                return (
                    <div className="">
                        {value.status && value.status === 'CLAIMABLE' ? 'Unclaimed' : 'Claimed'}
                    </div>
                );
            },
        },
    }];

    const tableData = props.list && props.list.length > 0
        ? props.list.map((item, index) =>
            [item.id, item.status, item.owner_name, item.email_address, item.claim],
        ) : [];

    return (
        <>
            <DataTable
                columns={columns}
                data={props.inProgress ? [] : tableData}
                options={options}/>
        </>
    );
};

TicketsInfoTable.propTypes = {
    fetchTicketsInfo: PropTypes.func.isRequired,
    inProgress: PropTypes.bool.isRequired,
    lang: PropTypes.string.isRequired,
    limit: PropTypes.number.isRequired,
    list: PropTypes.array.isRequired,
    skip: PropTypes.number.isRequired,
    sortBy: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired,
    order: PropTypes.string,
    searchKey: PropTypes.string,
};

const stateToProps = (state) => {
    return {
        lang: state.language,
        list: state.home.tickets.list,
        inProgress: state.home.tickets.inProgress,
        skip: state.home.tickets.skip,
        limit: state.home.tickets.limit,
        sortBy: state.home.tickets.sortBy,
        order: state.home.tickets.order,
        total: state.home.tickets.count,
        searchKey: state.home.tickets.searchKey,
    };
};

const actionsToProps = {
    fetchTicketsInfo,
};

export default withRouter(connect(stateToProps, actionsToProps)(TicketsInfoTable));
